/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 1rem;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li a {
  text-decoration: none;
  color: black;
}

.navbar-link:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Display box shadow */
  font-weight: bold;
  padding: 10px;
}
.active-page {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Display box shadow */
  font-weight: bold;
  background: #bdbdbd;
  padding: 10px;
}

.navbar-item1{
  width: 80px;
  text-align: center;
  font-weight: bold;
  padding: 10px;

}
.navbar-item2{
  width: 120px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}
.navbar-item3{
  width: 120px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}
.navbar-item4{
  width: 100px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}
.navbar-item5{
  width: 100px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}
.navbar-item6{
  width: 100px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}




/* header */ 
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) ;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 100px;
  margin-right: 10px;
}


/* Footer */
.footer {
  background-color: #000;
  color: #fff;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-column {
  display: flex;
  flex-direction: column;
}

.social-media {
  display: flex;
  margin-bottom: 1rem;
  width: 60px;
}

.social-media a {
  margin-right: 2rem;
  color: #fff;
  width: 60px;
}

.page-links {
  display: inline-block;
  margin-top: 40px;
  flex-direction: column;
}

.page-links a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 16px;
  padding: 20px;
}



.logo {
  width: 700px;
}
.icon{
  width: 80px;
  font-size: 40px;
}


/* General Styles */
.section1 {
  background-size:800px;
  background-position: bottom;
  text-align: center;
  height: 400px;
  color: #000000;
  font-size: 20px;
}


.two-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.two-column .column {
  flex: 1;
}

.two-column .column img {
  max-width: 100%;
}

.button {
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  margin-top: 20px;
  border-radius: 5px;
}

.button:hover {
  background-color: #333;
  transform: scale(1.05);
}

.three-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.three-column .column {
  flex: 1;
  text-align: center;
}

/* Section 4 Styles */
.section4 .column img {
  border-radius: 50%;
  max-width: 400px;
  align-items: center;
}
.name{
  font-size: 40px;
  margin-top: 0px;
  color: #fff;
  margin-bottom: 0px;
}
.post{
  margin-top: 0px;
  color: #fff;
  font-weight: normal;
  font-size: 20px;
}
.icon1{
  font-size: 50px;
  color: #ffffff;
}
.section4{
  border: #000;
  background-color: #000;
}

/* Section 5 Styles */
.section5 .column {
  text-align: center;
}
.section2{
  background-color: #000;
  margin-top: 0px;
  color: #fff;
}
.heading{
  font-size: 40px;
  font-weight: bold;
}
.logo_aboutus{
  padding: 100px;
  width: 600px;
}
.column{
  font-size: 20px;
  padding: 40px;
}
.button1{
  display: inline-block;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  text-decoration: none;
  margin-top: 20px;
  border-radius: 5px;
}
.button1:hover{
  background-color: #333;
  color: #fff;
  transform: scale(1.05);
}


.section3{
  padding: 50px;
}
.toptext{
  margin-top: 50px;
  font-size: 30px;margin-left: 280px;
  animation: slideInFromLeft 1s ease-in-out forwards;
}
.heading1{
  font-size: 44px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.section3,
.section5 {
  margin-top: 150px;
  margin-bottom: 150px;
}
.text{
  animation: slideInFromLeft 1s ease-in-out forwards;
}
.textright{
  animation: slideInFromRight 1s ease-in-out forwards;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on your header height */
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
    margin-left: 20px;
  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Set header width to 100% */
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    padding: 0; /* Remove padding */
  }
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 150px;
    margin-right: 10px;
  }
  .section1 {
    background-size:700px;
    background-position: bottom;
    text-align: center;
    height: 400px;
    color: #000000;
    font-size: 20px;
  }
  .logo_aboutus{
    padding: 10px;
    width: 600px;
  }
  .column{
    font-size: 20px;
    padding: 10px;
  }
  .section3,
.section5 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.three-column {
  flex-direction: column; /* Change flex direction to column */
  align-items: center; /* Center align columns */
}

.three-column .column {
  text-align: center; /* Center align content */
  margin-bottom: 30px; /* Add space between columns */
}
/* Footer */
.footer {
  background-color: #000;
  color: #fff;
  padding: rem;
  display: 2flex;
  justify-content: space-between;
  align-items: center;
}

.left-column {
  display: flex;
  flex-direction: column;
}

.social-media {
  display: flex;
  margin-bottom: 1rem;
  width: 60px;
}

.social-media a {
  margin-right: 2rem;
  color: #fff;
  width: 60px;
}

.page-links {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  align-items: center; /* Center align links */
}

.page-links a {
  margin-bottom: 10px; /* Add space between links */
  padding: 0px;
}

.logo {
  width: 200px;
}
.icon{
  width: 80px;
  font-size: 40px;
}
.toptext{
  margin-top: 50px;
  font-size: 20px;
  margin-left: 80px;
  animation: slideInFromLeft 1s ease-in-out forwards;
}
.two-column{
  flex-direction: column;
  align-items: center;
}
.two-column .column{
  padding: 10px;
}
.text{
  padding: 0px;
  margin: 0px;
}
}
