.heading{
    text-align: center;
}
.heading h1{
    font-size: 40px;
}
.company-details, .why-join-us {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    text-align: left;
}

.job-listing-container h2 {
    text-align: center; /* Center the heading */
}

.job-listings {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 150px; /* Adjust the margin-bottom value to increase space */
}

.job-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.job-card h2 {
    margin: 0 0 10px;
    font-size: 1.2em;
}

.job-card p {
    margin: 5px 0;
    font-size: 0.9em;
}

.job-card button {
    margin-top: auto;
    padding: 10px 15px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
}

.job-card button:hover {
    background-color: #414141;
}
.perks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.perks h2 {
    margin-bottom: 20px;
}

.perk-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.perk-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
}

.perk-item img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}